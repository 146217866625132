import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthContext";
import Header from "../../../../components/Header";
import PreInspectionsByDate from "./components/PreInspectionsByDate";
import InspectionsByDate from "./components/InspectionsByDate";

export default function Units() {
  const { user } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [activeTab, setActiveTab] = useState("inspection");
  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;

      setUserRole(user.currentCompanyRole.role.name);
    }
  }, [user]);

  const renderContent = () => {
    if (userRole === "owner") {
      return (
        <div>
          <div className="tabs flex space-x-4 mb-4">
            <button
              className={`tab px-4 py-2 rounded ${
                activeTab === "inspection"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => setActiveTab("inspection")}
            >
              Inspections
            </button>
            <button
              className={`tab px-4 py-2 rounded ${
                activeTab === "pre-inspection"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => setActiveTab("pre-inspection")}
            >
              Pre-Inspection
            </button>
          </div>
          <div className="tab-content">
            {activeTab === "inspection" ? (
              <InspectionsByDate />
            ) : (
              <PreInspectionsByDate />
            )}
          </div>
        </div>
      );
    } else if (userRole === "driver") {
      return <span>driver unit inspection</span>;
    } else {
      return (
        <div className="text-center">
          <p className="text-red-500">
            We don't have the unit inspection. Please contact your
            administrator!
          </p>
        </div>
      );
    }
  };

  return (
    <div className="flex">
      <div className="flex-1">
        <Header />
        <div className="container mx-auto p-4">{renderContent()}</div>
      </div>
    </div>
  );
}
