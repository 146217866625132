import React, { useEffect, useState } from "react";
import { FaTruck, FaTrailer, FaQuestion } from "react-icons/fa"; // Assuming you have react-icons installed
import { Link } from "react-router-dom";
import { getDriverUnits } from "../../../../api/driverAPI";
import { useAuth } from "../../../../AuthContext";

const DriverUnits = () => {
  const [units, setUnits] = useState([]);
  const { user } = useAuth();

  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      const getUnits = async () => {
        const companyId = user.currentCompanyRole.company._id;
        const units = await getDriverUnits(companyId);

        setUnits(units);
      };

      getUnits();
      loadedData = true;
    }
  }, [user]);

  return (
    <div className="container mx-auto p-4">
      {units.length === 0 ? (
        <p className="text-center text-gray-500">
          The company owner has not attached any units for you.
        </p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {units.map((unit) => (
            <Link
              to={`/dashboard/unit/${unit._id}`}
              key={unit._id}
              className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center hover:bg-gray-100"
            >
              {unit.type === "Tractor" ? (
                <FaTruck size={50} className="m-6" />
              ) : unit.type === "Trailer" ? (
                <FaTrailer size={50} className="m-6" />
              ) : (
                <FaQuestion size={50} className="m-6" />
              )}
              <p className="mt-6 text-sm ">
                {unit.make} {unit.model}
              </p>
              <p className="text-lg font-semibold">{unit.unitNumber}</p>
              <p className="text-sm ">{unit.vin}</p>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default DriverUnits;
