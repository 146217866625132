import axiosInstance from "./axiosInstance";

const getPictureDetails = async (pictureId) => {
  try {
    const response = await axiosInstance.get(
      `/inspection/${pictureId}/pictureDetails`
    );

    if (response.data.status === "fail") {
      throw new Error("Error in getPictureDetails");
    }

    return response.data.body;
  } catch (error) {
    console.error("Failed to fetch units", error);
    throw error;
  }
};

export { getPictureDetails };
