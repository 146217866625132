import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PrivateRoute = ({ element, ...rest }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  if (user && user.isNewUser && location.pathname !== "/dashboard/new-user") {
    return <Navigate to="/dashboard/new-user" />;
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
