import React, { useEffect, useState } from "react";
import { getDrivers } from "../../../../api/CompanyAPI";
import { useAuth } from "../../../../AuthContext";
import { FaCopy } from "react-icons/fa";

export default function DriverList() {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [drivers, setDrivers] = useState([]);

  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;

      fetchDrivers();
    }
  }, [user]);

  const fetchDrivers = async () => {
    try {
      const fetchedDrivers = await getDrivers(
        user.currentCompanyRole.company._id
      );
      const transformedDrivers = fetchedDrivers.map((driver) => ({
        id: driver._id,
        fullName: `${driver.user.firstName} ${driver.user.lastName}`,
        phone: driver.user.phone,
        email: driver.user.email,
      }));
      setDrivers(transformedDrivers);
    } catch (error) {
      console.error("Failed to fetch drivers", error);
    }
  };

  const filteredUsers = drivers.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone.includes(searchTerm) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search users..."
          className="border p-2 rounded mb-2 md:mb-0"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="overflow-x-auto">
        <div className="hidden md:block">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="py-2 px-4 border">Full Name</th>
                <th className="py-2 px-4 border">Phone</th>
                <th className="py-2 px-4 border">Email</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length === 0 ? (
                <tr>
                  <td colSpan="3" className="py-2 px-4 border text-center">
                    No Drivers
                  </td>
                </tr>
              ) : (
                filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="py-2 px-4 border">{user.fullName}</td>
                    <td className="py-2 px-4 border">{user.phone}</td>
                    <td className="py-2 px-4 border">{user.email}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="block md:hidden">
          {filteredUsers.length === 0 ? (
            <div className="text-center py-4">No Drivers</div>
          ) : (
            filteredUsers.map((user) => (
              <div key={user.id} className="bg-white border rounded mb-4 p-4">
                <div className="mb-2">
                  <strong>Full Name:</strong> {user.fullName}
                </div>
                <div className="mb-2">
                  <strong>Phone:</strong> {user.phone}
                </div>
                <div className="mb-2">
                  <strong>Email:</strong> {user.email}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
