import React, { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";
import Header from "../../../components/Header";
import PhotoInspection from "./components/driverComponents/PhotoInspection";
import VideoInspection from "./components/driverComponents/VideoInspection";
import UnitDetails from "./components/ownerComponents/UnitDetails";

export default function Units() {
  const { user } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [selectedTab, setSelectedTab] = useState("photo");

  let loadedData = false;

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!loadedData) {
      loadedData = true;

      setUserRole(user.currentCompanyRole.role.name);
    }
  }, [user]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="container mx-auto p-4 flex-1">
        {userRole === "owner" ? (
          <UnitDetails />
        ) : userRole === "driver" ? (
          <div className="flex flex-col items-center p-4">
            <div className="flex mb-4 w-full justify-center border-b-2 border-gray-200">
              <button
                className={`px-4 py-2 focus:outline-none ${
                  selectedTab === "photo"
                    ? "bg-gray-200 text-gray-700"
                    : "bg-gray-600 text-white"
                } rounded-t-lg`}
                onClick={() => setSelectedTab("photo")}
              >
                Photo
              </button>
              <button
                className={`px-4 py-2 focus:outline-none ${
                  selectedTab === "video"
                    ? "bg-gray-200 text-gray-700"
                    : "bg-gray-600 text-white"
                } rounded-t-lg`}
                onClick={() => setSelectedTab("video")}
              >
                Video
              </button>
            </div>
            <div className="w-full bg-white p-4 rounded-b-lg shadow-md">
              {selectedTab === "photo" && <PhotoInspection />}
              {selectedTab === "video" && <VideoInspection />}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-red-500">
              We don't recognize this unit. Please contact your administrator!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
