import axiosInstance from "./axiosInstance";

const getAllCompany = async (name) => {
  try {
    const response = await axiosInstance.get("/company/all");

    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const createCompany = async (name) => {
  try {
    const response = await axiosInstance.post("/company/create-company", {
      companyName: name,
    });

    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const getDrivers = async (companyId) => {
  try {
    const response = await axiosInstance.get(`/company/${companyId}/drivers`);

    return response.data;
  } catch (error) {
    console.error("Failed to get drivers API", error);
    throw error;
  }
};

export { createCompany, getAllCompany, getDrivers };
