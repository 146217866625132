import axiosInstance from "./axiosInstance";

const getInspections = async (company_id, unit_id) => {
  try {
    const response = await axiosInstance.get(
      `/inspection/${unit_id}/all?company_id=${company_id}`
    );

    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const createInspection = async (inspectionData) => {
  try {
    const response = await axiosInstance.post(`/inspection`, inspectionData);
    return response.data;
  } catch (error) {
    console.error("Failed to set inspection step", error);
    throw error;
  }
};

const deleteInspection = async (inspectionData) => {
  try {
    const response = await axiosInstance.post(
      `/inspection/delete`,
      inspectionData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete inspection", error);
    throw error;
  }
};

const updateInspection = async (inspectionData) => {
  try {
    const response = await axiosInstance.put(
      `/inspection/${inspectionData.id}`,
      inspectionData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update inspection", error);
    throw error;
  }
};


export { getInspections, createInspection, deleteInspection, updateInspection };
