import axiosInstance from "./axiosInstance";

const getUserInfo = async (userId) => {
  try {
    const response = await axiosInstance.get(`/user/${userId}/info`);

    return response.data;
  } catch (error) {
    console.error("Error fetching company info:", error);
  }
};

const getUserCompanyRole = async (userId) => {
  try {
    const response = await axiosInstance.get(`/user/${userId}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching company info:", error);
  }
};

const userCompanyConnection = async (userId, companyId) => {
  try {
    const response = await axiosInstance.put(`/user/${userId}`, { companyId });

    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

export { getUserInfo, getUserCompanyRole, userCompanyConnection };
