import axiosInstance from "./axiosInstance";

const getDriverUnits = async (companyId) => {
  try {
    const response = await axiosInstance.get(`/driver/${companyId}/units`);

    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const getDriverPicturesPerDay = async (unitId) => {
  try {
    const response = await axiosInstance.get(
      `/driver/${unitId}/pictures-per-day`
    );

    return response.data;
  } catch (error) {
    console.error("Failed to get DriverPicturesPerDay", error);
    throw error;
  }
};

export { getDriverUnits, getDriverPicturesPerDay };
