import React from "react";
import Header from "../../../components/Header";
import DriverList from "./components/DriversList";

export default function Drivers() {
  return (
    <div className="flex ">
      <div className="flex-1">
        <Header />

        <div className="container mx-auto p-4">
          <DriverList />
        </div>
      </div>
    </div>
  );
}
