import React from "react";
import {
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
  FaSpinner,
} from "react-icons/fa";

const MediaModal = ({
  isOpen,
  media,
  currentIndex,
  onClose,
  onPrev,
  onNext,
  loading,
}) => {
  if (!isOpen) return null;

  const currentMedia = media[currentIndex];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-full max-h-full h-3/4 w-3/4">
        <button
          className="absolute top-2 right-2 text-white bg-gray-800 bg-opacity-50 p-2 rounded-full"
          onClick={onClose}
        >
          <FaTimes size={24} />
        </button>
        {loading ? (
          <div className="flex flex-col items-center justify-center h-full">
            <FaSpinner className="animate-spin text-gray-600 mr-2" />
            <span>Loading... {currentMedia.fileName}</span>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center h-full">
              {currentMedia.type === "image" ? (
                <img
                  src={currentMedia.src}
                  alt={currentMedia.fileName}
                  className="max-w-full max-h-full"
                />
              ) : (
                <video
                  src={currentMedia.src}
                  controls
                  className="max-w-full max-h-full"
                />
              )}
            </div>
            <div className="text-center mt-2">
              <p>{currentMedia.fileName}</p>
            </div>
            <button
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white bg-gray-800 bg-opacity-50 p-2 rounded-full"
              onClick={onPrev}
            >
              <FaChevronLeft size={24} />
            </button>
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white bg-gray-800 bg-opacity-50 p-2 rounded-full"
              onClick={onNext}
            >
              <FaChevronRight size={24} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MediaModal;
