import React, { useEffect, useState } from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaTruck, FaUser } from "react-icons/fa";
import { getUserCompanyRole } from "../api/userAPI";

export default function Header() {
  const { user, updateUser, logout } = useAuth();
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const location = useLocation();
  const isDashboardUnits = location.pathname === "/dashboard/units";
  const isDashboardDrives = location.pathname === "/dashboard/drivers";

  const [companyInfo, setCompanyInfo] = useState({
    companyName: "Company Name",
    roleName: "Role",
  });

  let loadedData = false;

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      if (!user) {
        return;
      }

      if (user.hasOwnProperty("currentCompanyRole")) {
        setCompanyInfo({
          companyName: user.currentCompanyRole.company.name,
          roleName: user.currentCompanyRole.role.name,
        });

        return;
      }

      if (!loadedData) {
        loadedData = true; // fetch company info just one time

        const userCompanyRole = await getUserCompanyRole(user._id);

        const responseData = {
          companyName: userCompanyRole.company.name,
          roleName: userCompanyRole.role.name,
        };
        setCompanyInfo(responseData);

        // Update the user object using updateUser

        updateUser({ ...user, currentCompanyRole: userCompanyRole });
      }
    };

    fetchCompanyInfo();
  }, [user]);

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const navigate = useNavigate();

  if (!user) {
    return <p>Loading...</p>;
  }

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <header>
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="">
          <Link
            to={"/dashboard"}
            className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
          >
            <div className="flex items-center space-x-2">
              <span className="text-lg font-bold text-blue-600">
                {companyInfo.companyName}
              </span>
            </div>
          </Link>
        </div>

        <div className="relative flex hidden md:flex">
          <Link
            to={"/dashboard/units"}
            className={
              isDashboardUnits
                ? "rounded-md px-5 py-2 text-sm font-medium text-white bg-gray-900 flex items-center md:flex-col"
                : "rounded-md px-5 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 flex items-center md:flex-col"
            }
            aria-current="page"
          >
            <FaTruck className="mr-2 md:mr-0" />
            <span className="hidden md:inline">Units</span>
          </Link>

          {companyInfo.roleName === "owner" && (
            <Link
              to={"/dashboard/drivers"}
              className={
                isDashboardDrives
                  ? "rounded-md px-5 py-2 text-sm font-medium text-white bg-gray-900 flex items-center md:flex-col"
                  : "rounded-md px-5 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 flex items-center md:flex-col"
              }
            >
              <FaUser className="mr-2 md:mr-0" />
              <span className="hidden md:inline">Drivers</span>
            </Link>
          )}
        </div>

        <div className="relative">
          <Link
            className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
            aria-expanded={isProfileDropdownOpen}
            onClick={toggleProfileDropdown}
          >
            <span className="text-gray-500 hidden md:inline">
              {user.firstName} {user.lastName}
            </span>

            <svg
              className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
              />
            </svg>
          </Link>
          {isProfileDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
              <Link
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={handleLogout}
              >
                Logout
              </Link>
            </div>
          )}
        </div>
      </nav>

      <div className="fixed bottom-0 w-full md:top-0 md:bottom-auto md:w-auto md:flex md:justify-between bg-white shadow-md md:hidden">
        <div className="flex justify-around w-full md:w-auto">
          <Link
            to={"/dashboard/units"}
            className={
              isDashboardUnits
                ? "px-6 py-3 text-sm font-medium text-white flex flex-col items-center"
                : "px-6 py-3 text-sm font-medium text-gray-700 hover:bg-gray-100 flex flex-col items-center"
            }
          >
            {isDashboardUnits ? (
              <FaTruck className="text-blue-600 mb-1" />
            ) : (
              <FaTruck className="text-gray-400 mb-1" />
            )}
            <span className="text-xs md:text-sm text-gray-700">Units</span>
          </Link>

          {companyInfo.roleName === "owner" && (
            <Link
              to={"/dashboard/drivers"}
              className={
                isDashboardDrives
                  ? "px-6 py-3 text-sm font-medium text-white flex flex-col items-center"
                  : "px-6 py-3 text-sm font-medium text-gray-700 hover:bg-gray-100 flex flex-col items-center"
              }
            >
              {isDashboardDrives ? (
                <FaUser className="text-blue-600 mb-1" />
              ) : (
                <FaUser className="text-gray-400 mb-1" />
              )}
              <span className="text-gray-700 text-xs md:text-sm">Drivers</span>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}
