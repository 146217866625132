// import { Footer } from '@/components/Footer'
// import { Header } from '@/components/Header'

import { Outlet } from "react-router-dom";

export default function Layout({ children }) {
  return (
    <>
      {/* <Header /> */}
      <main className="flex-auto">
        <Outlet />
      </main>
      {/* <Footer /> */}
    </>
  );
}
