import React, { useEffect, useState } from "react";
import { useAuth } from "./../../../../../AuthContext";
import { getInspections } from "./../../../../../api/inspectionAPI";
import {
  createInspection,
  updateInspection,
  deleteInspection,
} from "./../../../../../api/inspectionAPI";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronRight, FaChevronUp, FaPlay } from "react-icons/fa";
import { Button } from "./../../../../../components/Button";
import Pictures from "./subComponents/Pictures";
import InspectionDetails from "./subComponents/InspectionDetails";
import ReviewSign from "./subComponents/ReviewSign";
import DriverSelect from "./subComponents/DriverSelect";
import PrevInspections from "./subComponents/PrevInspections";

const InspectionsByDate = () => {
  const { user } = useAuth();
  const { unitId } = useParams();

  const [inspectionId, setInspectionId] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [newInspection, setNewInspection] = useState({
    photos: [],
    videos: [],
    checklist: {},
    description: "",
    uploadedPhotos: [],
    to_user_id: null,
  });

  const handleStartNewInspection = async () => {
    setCurrentStep(1);

    // Create a new inspection in the backend
    const createNewInspection = await createInspection({
      type: "inspection",
      company_id: user.currentCompanyRole.company._id,
      user_id: user._id,
      unit_id: unitId,
    });

    if (createNewInspection.status === "fail") {
      console.error(
        "Failed to create new inspection",
        createNewInspection.body.picture_ids
      );
      setNewInspection((prev) => ({
        ...prev,
        uploadedPhotos: createNewInspection.body.picture_ids,
        description: createNewInspection.body.description,
        to_user_id: createNewInspection.body.to_user_id,
      }));

      setCurrentStep(createNewInspection.body.currentStep);
    }

    setInspectionId(createNewInspection.body._id);
  };

  const handleCancelInspection = () => {
    const confirmCancel = window.confirm(
      "All data you have input for the current inspection will be deleted. Do you want to proceed?"
    );
    if (confirmCancel) {
      deleteInspection({
        type: "inspection",
        currentStep,
        company_id: user.currentCompanyRole.company._id,
        user_id: user._id,
        unit_id: unitId,
        inspectionId,
      });

      setCurrentStep(0);
      setNewInspection({
        photos: [],
        videos: [],
        checklist: {},
        comments: "",
        description: "",
        uploadedPhotos: [],
      });
    }
  };

  const handleDescriptionChange = (description) => {
    setNewInspection((prev) => ({ ...prev, description }));
  };

  const driverChange = (driver) => {
    setNewInspection((prev) => ({ ...prev, to_user_id: driver.value }));
  };

  const signChange = (signature) => {
    updateInspection({
      id: inspectionId,
      signature: signature,
    });
  };

  const handleNextStep = () => {
    if (currentStep === 2) {
      // Save the inspection to the backend
      // Reset the inspection process
      updateInspection({
        id: inspectionId,
        description: newInspection.description,
        to_user_id: newInspection.to_user_id,
      });
    }

    setCurrentStep(currentStep + 1);
    updateInspection({ id: inspectionId, currentStep: currentStep + 1 });
  };

  const handlePreviousStep = () => {
    if (currentStep === 2) {
      updateInspection({
        id: inspectionId,
        description: newInspection.description,
        to_user_id: newInspection.to_user_id,
      });
    }

    setCurrentStep(currentStep - 1);
    updateInspection({ id: inspectionId, currentStep: currentStep - 1 });
  };

  const handleFinishInspection = () => {
    // Save the inspection to the backend
    setCurrentStep(currentStep + 1);
    updateInspection({ id: inspectionId, currentStep: currentStep + 1 });

    // Reset the inspection process
    setCurrentStep(0);
    setNewInspection({
      photos: [],
      videos: [],
      checklist: {},
      description: "",
      uploadedPhotos: [],
      to_user_id: null,
    });
  };

  const newPhotoAdded = (response) => {
    const photoIds = response.uploadResults.map((photo) => photo._id);

    setNewInspection((prev) => ({
      ...prev,
      uploadedPhotos: Array.isArray(photoIds)
        ? [...prev.uploadedPhotos, ...photoIds]
        : [...prev.uploadedPhotos, photoIds],
    }));
  };

  const handleChecklistChange = (item, value) => {
    setNewInspection((prev) => ({
      ...prev,
      checklist: {
        ...prev.checklist,
        [item]: value,
      },
    }));
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              Step 1: Upload Photos/Videos
            </h2>
            {/* Upload component for photos and videos */}
            <Pictures
              companyId={user.currentCompanyRole.company._id}
              inspectionId={inspectionId}
              onNewPhotoAdded={newPhotoAdded}
            />

            {newInspection.uploadedPhotos.length > 0 && (
              <span className="text-green-500 font-semibold center flex justify-center">
                {newInspection.uploadedPhotos.length} Photos Uploaded On The
                Cloud
              </span>
            )}

            <div className="flex justify-between">
              <div></div> {/* Placeholder for alignment */}
              <Button onClick={handleNextStep}>Next</Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Step 2: Checklist</h2>
            {/* Driver select component */}
            <DriverSelect
              user={user}
              currentDriver={newInspection.to_user_id}
              onDriverChange={driverChange}
            />

            {/* Checklist form */}
            <InspectionDetails
              description={newInspection.description}
              onDescriptionChange={handleDescriptionChange}
            />

            <div className="flex justify-between">
              <Button onClick={handlePreviousStep}>Previous</Button>
              <Button onClick={handleNextStep}>Next</Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Step 3: Review & Sign</h2>
            {/* Review and sign component */}
            <ReviewSign onSignChange={signChange} />
            <div className="flex justify-between">
              <Button onClick={handlePreviousStep}>Previous</Button>
              <Button onClick={handleFinishInspection}>Finish</Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <Button
          onClick={handleStartNewInspection}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Start / Continue Inspection
        </Button>
        {currentStep > 0 && (
          <Button
            onClick={handleCancelInspection}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Cancel Inspection
          </Button>
        )}
      </div>
      {currentStep > 0 && (
        <div className="bg-white p-4 rounded shadow-md">
          {renderStepContent()}
        </div>
      )}
      <div>
        <h2 className="text-xl font-bold mb-4">Previous Inspections</h2>

        <PrevInspections />
      </div>
    </div>
  );
};

export default InspectionsByDate;
