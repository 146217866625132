import React, { useEffect, useState } from 'react'
import {
  FaEdit,
  FaTrash,
  FaPlus,
  FaTruck,
  FaTrailer,
  FaEye,
} from 'react-icons/fa'
import Select from 'react-select'
import { Button } from '../../../../components/Button'
import {
  getUnits,
  createUnit,
  updateUnit,
  deleteUnit,
} from '../../../../api/unitAPI'
import { getDrivers } from '../../../../api/CompanyAPI'
import { useAuth } from '../../../../AuthContext'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function OwnerUnitsTable() {
  const { user } = useAuth()
  const navigate = useNavigate()

  const [units, setUnits] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showModal, setShowModal] = useState(false)

  const [modalAction, setModalAction] = useState('create') // create or edit
  const [unit, setUnit] = useState({
    type: '',
    unitNumber: '',
    vin: '',
    year: '',
    make: '',
    model: '',
    users: [],
  })
  const [drivers, setDrivers] = useState([
    { value: 'driver1', label: 'Driver 1' },
  ])

  let loadedData = false

  useEffect(() => {
    if (!user) {
      return
    }

    if (!loadedData) {
      loadedData = true

      // Fetch units data from the API
      const fetchUnits = async () => {
        try {
          const fetchedUnits = await getUnits(
            user.currentCompanyRole.company._id
          )

          setUnits(fetchedUnits)
        } catch (error) {
          console.error('Failed to fetch units', error)
        }
      }

      fetchUnits()
    }
  }, [user, navigate])

  const fetchDrivers = async () => {
    // Fetch drivers data from the API

    try {
      const fetchedDrivers = await getDrivers(
        user.currentCompanyRole.company._id
      ) // need to send the company id

      const drivers = fetchedDrivers.map((driver) => ({
        value: driver.user._id,
        label: `${driver.user.firstName} ${driver.user.lastName}`,
      }))

      setDrivers(drivers)
    } catch (error) {
      console.error('Failed to fetch units', error)
    }
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setUnit({ ...unit, [name]: value })
  }

  const handleDriverChange = (selectedOptions) => {
    setUnit({ ...unit, users: selectedOptions })
  }

  const handleCreateUnit = async () => {
    try {
      unit.company = user.currentCompanyRole.company._id

      const createdUnit = await createUnit(unit)
      setUnits([...units, createdUnit])
      setShowModal(false)
      setUnit({
        type: '',
        unitNumber: '',
        vin: '',
        year: '',
        make: '',
        model: '',
        users: [],
      })
    } catch (error) {
      console.error('Failed to create company', error)
    }
  }

  const handleUpdateUnit = async () => {
    try {
      const updatedUnit = await updateUnit(unit)

      // Find the index of the unit being updated
      const index = units.findIndex((u) => u._id === updatedUnit._id)

      // Create a new array with the updated unit
      const updatedUnits = [
        ...units.slice(0, index),
        updatedUnit,
        ...units.slice(index + 1),
      ]

      // Update the state with the new array
      setUnits(updatedUnits)

      setShowModal(false)
      setUnit({
        type: '',
        unitNumber: '',
        vin: '',
        year: '',
        make: '',
        model: '',
        users: [],
      })
    } catch (error) {
      console.error('Failed to update unit', error)
    }
  }

  const handleDeleteClick = async (unit) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this unit?'
    )

    if (!isConfirmed) {
      return // Abort the deletion if the user cancels
    }

    try {
      await deleteUnit(unit)

      // Filter the units array to remove the deleted unit
      const updatedUnits = units.filter((u) => u._id !== unit._id)

      // Update the state with the new array
      setUnits(updatedUnits)
    } catch (error) {
      console.error('Failed to delete unit', error)
    }
  }

  const handleEditClick = (unit) => {
    fetchDrivers() // Fetch drivers before editing a unit
    setModalAction('update')

    setUnit(unit)
    setShowModal(true)
  }

  const handleCancelClick = () => {
    setShowModal(false)
    setUnit({
      type: '',
      unitNumber: '',
      vin: '',
      year: '',
      make: '',
      model: '',
      users: [],
    })
  }

  const filteredUnits = units.filter((unit) => {
    const searchTermLower = searchTerm.toLowerCase()
    return (
      unit.type.toLowerCase().includes(searchTermLower) ||
      unit.unitNumber.toLowerCase().includes(searchTermLower) ||
      unit.vin.toLowerCase().includes(searchTermLower) ||
      unit.year.toString().includes(searchTerm) ||
      unit.make.toLowerCase().includes(searchTermLower) ||
      unit.model.toLowerCase().includes(searchTermLower) ||
      (unit.users &&
        unit.users.some((user) =>
          user.label.toLowerCase().includes(searchTermLower)
        ))
    )
  })

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search units..."
          value={searchTerm}
          onChange={handleSearch}
          className="border p-2 rounded mb-2 md:mb-0"
        />
        <button
          className="bg-blue-500 text-white p-2 rounded flex items-center"
          onClick={async () => {
            await fetchDrivers()
            setShowModal(true)
            setModalAction('create')
          }}
        >
          <FaPlus className="mr-2" />
          Create Unit
        </button>
      </div>
      <div className="overflow-x-auto">
        <div className="hidden md:block">
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border">Unit #</th>
                <th className="py-2 px-4 border">VIN</th>
                <th className="py-2 px-4 border">Make</th>
                <th className="py-2 px-4 border">Model</th>
                <th className="py-2 px-4 border">Year</th>
                <th className="py-2 px-4 border">Driver(s)</th>
                <th className="py-2 px-4 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUnits.length === 0 ? (
                <tr>
                  <td colSpan="7" className="py-2 px-4 border text-center">
                    No Units Created
                  </td>
                </tr>
              ) : (
                filteredUnits.map((unit, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border">
                      <Link
                        to={`/dashboard/unit/${unit._id}`}
                        className="flex items-center"
                      >
                        {unit.type === 'Tractor' && (
                          <FaTruck className="mr-2" />
                        )}
                        {unit.type === 'Trailer' && (
                          <FaTrailer className="mr-2" />
                        )}
                        {unit.unitNumber}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border">
                      <Link to={`/dashboard/unit/${unit._id}`}>{unit.vin}</Link>
                    </td>
                    <td className="py-2 px-4 border">
                      <Link to={`/dashboard/unit/${unit._id}`}>
                        {unit.make}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border">
                      <Link to={`/dashboard/unit/${unit._id}`}>
                        {unit.model}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border">
                      <Link to={`/dashboard/unit/${unit._id}`}>
                        {unit.year}
                      </Link>
                    </td>
                    <td className="py-2 px-4 border">
                      {unit.users && unit.users.length > 0 ? (
                        unit.users.map((user, userIndex) => (
                          <div key={userIndex}>{user.label}</div>
                        ))
                      ) : (
                        <div>No Users</div>
                      )}
                    </td>
                    <td className="py-2 px-4 border">
                      <button
                        className="bg-yellow-500 text-white p-1 rounded mr-2"
                        onClick={() => handleEditClick(unit)}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="bg-red-500 text-white p-1 rounded"
                        onClick={() => handleDeleteClick(unit)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="block md:hidden">
          {filteredUnits.length === 0 ? (
            <div className="text-center py-4">No Units Created</div>
          ) : (
            filteredUnits.map((unit, index) => (
              <div key={index} className="bg-white border rounded mb-4 p-4">
                <div className="flex items-center mb-2">
                  {unit.type === 'Tractor' && <FaTruck className="mr-2" />}
                  {unit.type === 'Trailer' && <FaTrailer className="mr-2" />}
                  <Link to={`/dashboard/unit/${unit._id}`}>
                    {unit.unitNumber}
                  </Link>
                </div>
                <div className="mb-2">
                  <strong>VIN:</strong> {unit.vin}
                </div>
                <div className="mb-2">
                  <strong>Make:</strong> {unit.make}
                </div>
                <div className="mb-2">
                  <strong>Model:</strong> {unit.model}
                </div>
                <div className="mb-2">
                  <strong>Year:</strong> {unit.year}
                </div>
                <div className="mb-2">
                  <strong>Driver(s):</strong>{' '}
                  {unit.users && unit.users.length > 0 ? (
                    unit.users.map((user, userIndex) => (
                      <div key={userIndex}>{user.label}</div>
                    ))
                  ) : (
                    <div>No Users</div>
                  )}
                </div>
                <div className="flex justify-end">
                  <button
                    className="bg-yellow-500 text-white p-1 rounded mr-2"
                    onClick={() => handleEditClick(unit)}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="bg-red-500 text-white p-1 rounded"
                    onClick={() => handleDeleteClick(unit)}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-xl mb-4">
              {modalAction === 'create' ? 'Create New Unit' : 'Update Unit'}
            </h2>
            <div className="mb-4">
              <label className="block mb-1">Type</label>
              <select
                name="type"
                value={unit.type}
                onChange={handleInputChange}
                required
                className="border p-2 rounded w-full"
              >
                <option value="">Select Type</option>
                <option value="Tractor">Tractor</option>
                <option value="Trailer">Trailer</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1">Unit #</label>
              <input
                type="text"
                name="unitNumber"
                value={unit.unitNumber}
                onChange={handleInputChange}
                required
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">VIN</label>
              <input
                type="text"
                name="vin"
                value={unit.vin}
                onChange={handleInputChange}
                required
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Year</label>
              <input
                type="text"
                name="year"
                value={unit.year}
                onChange={handleInputChange}
                required
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Make</label>
              <input
                type="text"
                name="make"
                value={unit.make}
                onChange={handleInputChange}
                required
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Model</label>
              <input
                type="text"
                name="model"
                value={unit.model}
                onChange={handleInputChange}
                required
                className="border p-2 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Drivers</label>
              <Select
                isMulti
                name="drivers"
                options={drivers}
                className="basic-multi-select"
                classNamePrefix="select"
                value={unit.users}
                onChange={handleDriverChange}
              />
            </div>
            <div className="flex justify-end">
              <Button className="p-2 rounded mr-2" onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                onClick={
                  modalAction === 'create' ? handleCreateUnit : handleUpdateUnit
                }
              >
                {modalAction === 'create' ? 'Create' : 'Update'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
